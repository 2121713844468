import React from "react"

const TwitchChat = ({ username }) => {
  return (
    <div className="twitch-chat">
      <iframe
        id="chat-embed"
        frameBorder="0"
        scrolling="enabled"
        src={`https://www.twitch.tv/embed/${username}/chat?darkpopout&parent=fgcharts.com&parent=www.fgcharts.com`}
        height="500"
        width="100%"
      ></iframe>
    </div>
  )
}

export default TwitchChat
