import { useState, useEffect } from "react"
import {
  fetchStream,
  fetchStreamViewers,
  fetchGamePlaying,
  fetchStreamerFollowers,
  fetchStreamerGeneralData,
} from "../utils/twitch"

const useStream = (streamer, affiliated) => {
  const [stream, setStream] = useState(null)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    const func = async () => {
      const response = await fetchStream(streamer)
      const streamViewers = await fetchStreamViewers(response.id)
      const game = await fetchGamePlaying(response.game_id)
      const followers = await fetchStreamerFollowers(response.id)
      const generalData = await fetchStreamerGeneralData(response.id)

      const x = {
        username: response.display_name,
        title: response.title,
        affiliated: affiliated,
        startedAt: response.started_at,
        viewers: streamViewers.length ? streamViewers[0].viewer_count : 0,
        game: game ? game.name : "//",
        followers: followers,
        description: generalData.data[0].description,
        channelViews: generalData.data[0].view_count,
      }

      setLoading(false)
      setStream(x)
    }

    func()
  }, [])

  return {
    stream,
    loading,
  }
}

export default useStream
