import React from "react"

const TwitchPlayer = ({ username }) => {
  return (
    <div className="twitch-player">
      <iframe
        src={`https://player.twitch.tv/?channel=${username}&parent=fgcharts.com&parent=www.fgcharts.com`}
        frameBorder="0"
        allowFullScreen={true}
        allow="autoplay"
        style={{ width: "100%", height: 500 }}
      ></iframe>
    </div>
  )
}

export default TwitchPlayer
