import React from "react"
import styled from "styled-components"
import { graphql, navigate } from "gatsby"
import Select from "react-select"
import moment from "moment"
import useStream from "../hooks/useStream"
import Spinner from "../components/loader"
import Layout from "../components/layout"
import SEO from "../components/seo"
import TwitchLogoIcon from "../images/twitch-icon.svg"
import OfflineIcon from "../images/offline-icon.svg"
import StarIcon from "../images/star.svg"
import { customStylesGamePage } from "../styles/customSelectStyles"
import { Container } from "../components/styled/container"
import TwitchChat from "../components/twitch/twitchChat"
import TwitchPlayer from "../components/twitch/twitchPlayer"
import { AdBanner, AdLayout } from "../components/adBanner/index"

const SearchInput = styled.div`
  width: 264px;
  margin-bottom: 16px;

  @media (max-width: 576px) {
    width: 100%;
  }
`

const TopContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  padding-bottom: 24px;
  padding-top: 88px;
`

const TwitchPage = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 768px) {
    margin-bottom: 8px;
  }
`

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 744px 1fr;
  align-items: center;

  @media (max-width: 1064px) {
    .streamer-data {
      display: none;
    }
    grid-template-columns: 1fr;
  }
`

const ContentWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 16px;
  padding: 40px;
`

const StreamersCardTitle = styled.h4`
  font-weight: ${props => props.theme.fontWeight.medium};
  color: ${props => props.theme.colors.gameCardTitle};
  font-size: 12px;
  line-height: 16px;
  text-transform: uppercase;
  margin-bottom: 8px;
`

const StreamersCardData = styled.p`
  color: ${props => props.theme.colors[props.color]};
  margin-bottom: 0;
`

const StreamStatus = styled.div`
  color: #fbfbfc;
  display: flex;
  align-items: center;
  line-height: 1.5;

  @media (max-width: 576px) {
    justify-content: center;
  }
`

const StreamerTemplate = ({ data }) => {
  const { stream, loading } = useStream(
    data.strapiStreamers.username,
    data.strapiStreamers.affiliated
  )

  let options = []

  data.allStrapiStreamers.edges.map(({ node }) => {
    let obj = {
      value: node.slug,
      label: node.username,
    }

    return options.push(obj)
  })

  return (
    <Layout>
      <AdLayout>
        <AdBanner slot="6517054732" />
        <Container main>
          <SEO
            title={`${data.strapiStreamers.username} - FGCharts`}
            description="Streamers we follow and support."
          />
          <TopContainer>
            <TwitchPage>
              <img
                src={TwitchLogoIcon}
                alt="Twitch logo"
                style={{ marginBottom: 0, marginRight: 12 }}
              />
              <a
                href={`https://www.twitch.tv/${data.strapiStreamers.username}`}
                style={{ color: "white" }}
              >
                Twitch Page
              </a>
            </TwitchPage>

            <SearchInput>
              <Select
                closeMenuOnSelect={true}
                isClearable={false}
                onChange={e => navigate(`/streamers/${e.value}`)}
                isMulti={false}
                options={options}
                styles={customStylesGamePage}
                placeholder={"Search featured Streamers"}
                noOptionsMessage={() => "Streamer not found"}
              />
            </SearchInput>
          </TopContainer>
          <Wrapper>
            <TwitchPlayer username={data.strapiStreamers.username} />
            <ContentWrapper className="streamer-data">
              {loading ? (
                <Spinner span="span 2" />
              ) : (
                <>
                  <div style={{ gridColumn: "span 2" }}>
                    <StreamersCardTitle>Stream Title</StreamersCardTitle>
                    <StreamersCardData style={{ textTransform: "capitalize" }}>
                      {stream && stream.title}
                    </StreamersCardData>
                  </div>
                  <div style={{ gridColumn: "span 2" }}>
                    <StreamersCardTitle>Live Since</StreamersCardTitle>
                    <StreamersCardData style={{ textTransform: "capitalize" }}>
                      {stream && stream.viewers > 0 ? (
                        moment(stream.startedAt).format("dddd D MMM, HH:mm")
                      ) : (
                        <StreamStatus>
                          <img
                            src={OfflineIcon}
                            alt="Grey circle icon, indicating that the streamer is offline"
                            style={{
                              marginRight: 8,
                              marginBottom: 0,
                            }}
                          />
                          Offline
                        </StreamStatus>
                      )}
                    </StreamersCardData>
                  </div>

                  <div>
                    <StreamersCardTitle>Viewers</StreamersCardTitle>
                    <StreamersCardData
                      style={{ textTransform: "capitalize", color: "#8FED6E" }}
                    >
                      {stream && stream.viewers}
                    </StreamersCardData>
                  </div>
                  <div>
                    <StreamersCardTitle>Affiliated Streamer</StreamersCardTitle>
                    <StreamersCardData style={{ textTransform: "capitalize" }}>
                      {stream && stream.affiliated ? (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            src={StarIcon}
                            alt="star icon"
                            style={{ marginBottom: 0, marginRight: 8 }}
                          />{" "}
                          Yes
                        </div>
                      ) : (
                        "No"
                      )}
                    </StreamersCardData>
                  </div>
                  <div>
                    <StreamersCardTitle>Channel Followers</StreamersCardTitle>
                    <StreamersCardData
                      style={{ textTransform: "capitalize", color: "#FFCD56" }}
                    >
                      {stream && stream.followers}
                    </StreamersCardData>
                  </div>
                  <div>
                    <StreamersCardTitle>channel View Count</StreamersCardTitle>
                    <StreamersCardData
                      style={{ textTransform: "capitalize", color: "#F19954" }}
                    >
                      {stream && stream.channelViews}
                    </StreamersCardData>
                  </div>
                  <div style={{ gridColumn: "span 2" }}>
                    <StreamersCardTitle>ABOUT STREAMER</StreamersCardTitle>
                    <StreamersCardData style={{ textTransform: "capitalize" }}>
                      {stream && stream.description}
                    </StreamersCardData>
                  </div>
                </>
              )}
            </ContentWrapper>
          </Wrapper>
          <TwitchChat username={data.strapiStreamers.username} />
        </Container>
        <AdBanner slot="6517054732" />
      </AdLayout>
    </Layout>
  )
}

export const query = graphql`
  query StreamerTemplate($slug: String!) {
    allStrapiStreamers {
      edges {
        node {
          slug
          username
        }
      }
    }
    strapiStreamers(slug: { eq: $slug }) {
      username
      affiliated
    }
  }
`

export default StreamerTemplate
